<template>
  <div>
    <v-card class="ma-1 pa-1">
      <v-card-title class="pa-1">
        <span>{{ $t("documentTemplates.index") }}</span>
        <v-spacer></v-spacer>
        <!-- <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :placeholder="$t('search')"
          style="width: 100px !important"
          @keyup.native.enter="getList"
          outlined
          dense
          single-line
          hide-details
        ></v-text-field> -->
        <v-btn
          color="#6ac82d"
          x-small
          dark
          fab
          @click="$router.push('/document-templates/create')"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        dense
        fixed-header
        :loading-text="$t('loadingText')"
        :no-data-text="$t('noDataText')"
        :height="screenHeight"
        :loading="loading"
        :headers="headers"
        :items="items"
        class="ma-1"
        style="border: 1px solid #aaa"
        single-expand
        item-key="id"
        :server-items-length="server_items_length"
        item-text="template_name"
        :footer-props="{
          itemsPerPageOptions: [20, 50, 100, -1],
          itemsPerPageAllText: $t('itemsPerPageAllText'),
          itemsPerPageText: $t('itemsPerPageText'),
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right',
        }"
        :options.sync="dataTableOptions"
        :disable-pagination="true"
        disable-sort
        @update:page="updatePage"
        @update:items-per-page="updatePerPage"
      >
        <template v-slot:body.prepend="{ item }">
          <tr>
            <td></td>
            <td>
              <v-text-field
                v-model="filter.search"
                :placeholder="$t('search')"
                style="width: 100% !important; padding: 10px"
                @keyup.native.enter="getList"
                dense
                single-line
                hide-details
              ></v-text-field>
            </td>
            <td>
              <v-autocomplete
                clearable
                v-model="filter.department_id"
                :items="
                  departments.map((v) => ({
                    text: v['name_' + $i18n.locale],
                    value: v.id,
                  }))
                "
                :rules="[(v) => !!v || $t('input.required')]"
                hide-details
                dense
                @change="getList"
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                clearable
                v-model="filter.document_type_id"
                :items="
                  documentTypes.map((v) => ({
                    text: v['name_' + $i18n.locale],
                    value: v.id,
                  }))
                "
                :rules="[(v) => !!v || $t('input.required')]"
                hide-details
                dense
                @change="getList"
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                clearable
                v-model="filter.branch_id"
                :items="
                  branches.map((v) => ({
                    text: v.name,
                    value: v.id,
                  }))
                "
                :rules="[(v) => !!v || $t('input.required')]"
                hide-details
                dense
                @change="getList"
              ></v-autocomplete>
            </td>

            <td></td>
          </tr>
        </template>

        <template v-slot:item.id="{ item }">
          <span
            style="white-space: normal; display: block; cursor: pointer"
            @click="$router.push('/document-templates/update/' + item.id)"
            >{{
              items
                .map(function (x) {
                  return x.id;
                })
                .indexOf(item.id) + 1
            }}</span
          >
        </template>
        <template v-slot:item.template_name="{ item }">
          <span
            @click="$router.push('/document-templates/update/' + item.id)"
            style="white-space: normal; display: block; cursor: pointer"
            v-if="item"
            >{{ item["name_" + $i18n.locale] }}</span
          >
        </template>
        <template v-slot:item.department_id="{ item }">
          <span
            @click="$router.push('/document-templates/update/' + item.id)"
            style="white-space: normal; display: block; cursor: pointer"
            v-if="item.department"
            >{{ item.department["name_" + $i18n.locale] }}</span
          >
        </template>
        <template
          v-slot:item.document_type_id="{ item }"
          @click="$router.push('/document-templates/update/' + item.id)"
        >
          <span
            @click="$router.push('/document-templates/update/' + item.id)"
            style="white-space: normal; display: block; cursor: pointer"
            v-if="item.document_type"
            >{{ item.document_type["name_" + $i18n.locale] }}</span
          >
        </template>
        <template v-slot:item.department="{ item }">
          <span
            @click="$router.push('/document-templates/update/' + item.id)"
            style="white-space: normal; display: block; cursor: pointer"
            >{{ getCompany(item.is_main_branch) }}
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            color="blue"
            small
            text
            @click="$router.push('/document-templates/update/' + item.id)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn color="red" small text @click="deleteItem(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialog"
      @keydown.esc="dialog = false"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("documentTemplates.index") }}</span>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined x-small fab class @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form @keyup.native.enter="save" ref="dialogForm">
            <v-row>
              <v-col cols="6">
                <label for>{{ $t("department.index") }}</label>
                <v-autocomplete
                  clearable
                  v-model="form.department_id"
                  :items="department"
                  :rules="[(v) => !!v || $t('input.required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6">
                <label for>{{ $t("documentTypes.index") }}</label>
                <v-autocomplete
                  clearable
                  v-model="form.document_type_id"
                  :items="documentType"
                  :rules="[(v) => !!v || $t('input.required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6">
                <label for>{{ $t("signerGroup.signer_group_id") }}</label>
                <v-autocomplete
                  clearable
                  v-model="form.signer_group_id"
                  :items="signerGroup"
                  :rules="[(v) => !!v || $t('input.required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6">
                <label for>{{ $t("name_uz_latin") }}</label>
                <v-text-field
                  v-model="form.name_uz_latin"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <label for>{{ $t("name_uz_cyril") }}</label>
                <v-text-field
                  v-model="form.name_uz_cyril"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <label for>{{ $t("name_ru") }}</label>
                <v-text-field
                  v-model="form.name_ru"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <label for>{{ $t("description_uz_latin") }}</label>
                <v-text-field
                  v-model="form.decription_uz_latin"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <label for>{{ $t("description_uz_cyril") }}</label>
                <v-text-field
                  v-model="form.decription_uz_cyril"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <label for>{{ $t("description_ru") }}</label>
                <v-text-field
                  v-model="form.decription_ru"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <small color="red">{{ $t("input_required") }}</small>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save">{{ $t("save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="StaffDialog" persistent max-width="800px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <span class="headline">{{ "" }}</span>
          <v-spacer></v-spacer>

          <v-btn
            color="red"
            outlined
            x-small
            fab
            class
            @click="StaffDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form @keyup.native.enter="StaffSave" ref="staffDialogform">
            <v-row>
              <v-col cols="6">
                <label for>{{
                  $t("documentTemplates.attribute_name_uz_latin")
                }}</label>
                <v-text-field
                  v-model="StaffForm.attribute_name_uz_latin"
                  :rules="[(v) => !!v || $t('input.required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <label for>{{
                  $t("documentTemplates.attribute_name_uz_cyril")
                }}</label>
                <v-text-field
                  v-model="StaffForm.attribute_name_uz_cyril"
                  :rules="[(v) => !!v || $t('input.required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <label for>{{
                  $t("documentTemplates.attribute_name_ru")
                }}</label>
                <v-text-field
                  v-model="StaffForm.attribute_name_ru"
                  :rules="[(v) => !!v || $t('input.required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <label for>{{
                  $t("documentTemplates.value_min_lenght")
                }}</label>
                <v-text-field
                  v-model="StaffForm.value_min_lenght"
                  :rules="[(v) => !!v || $t('input.required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <label for>{{
                  $t("documentTemplates.value_max_lenght")
                }}</label>
                <v-text-field
                  v-model="StaffForm.value_max_lenght"
                  :rules="[(v) => !!v || $t('input.required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <label for>{{ $t("documentTemplates.description") }}</label>
                <v-text-field
                  v-model="StaffForm.description"
                  :rules="[(v) => !!v || $t('input.required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label for>{{ $t("documentTemplates.dataType") }}</label>
                <v-autocomplete
                  clearable
                  v-model="StaffForm.data_type_id"
                  :items="dataTypes"
                  :rules="[(v) => !!v || $t('input.required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveStaff(item)">{{
            $t("save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loading" width="300" hide-overlay>
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("loadingText") }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const axios = require("axios").default;
import Swal from "sweetalert2";
export default {
  data() {
    return {
      filter: {},
      page: 1,
      from: 0,
      StaffDialog: false,
      server_items_length: -1,
      dataTableOptions: { page: 1, itemsPerPage: 50 },
      dataTableValue: [],
      loading: false,
      search: "",
      dialog: false,
      editMode: null,
      items: [],
      regions: [],
      form: {},
      dialogHeaderText: "",
      branches: [],
      departments: [],
      documentTypes: [],
      createdAtMenu2: false,
      department: [],
      documentType: [],
      signerGroup: [],
      dataTypes: [],
      StaffForm: {},
    };
  },
  computed: {
    getCompany() {
      return (companyId) => {
        return this.branches?.find((branch) => branch.id == companyId)?.name;
      };
    },
    screenHeight() {
      return window.innerHeight - 170;
    },
    headers() {
      return [
        { text: "#", value: "id", align: "center", width: 30 },
        {
          text: this.$t("name_" + this.$i18n.locale),
          value: "template_name",
        },
        { text: this.$t("department_id"), value: "department_id" },
        { text: this.$t("documentTypes.index"), value: "document_type_id" },
        { text: "Company", value: "department" },
        // { text: this.$t("name_uz_cyril"), value: "name_uz_cyril" },
        // { text: this.$t("name_ru"), value: "name_ru" },
        {
          text: this.$t("actions"),
          value: "actions",
          width: "2%",
          align: "center",
        },
      ];
    },
  },
  methods: {
    saveStaff() {
      if (this.$refs.staffDialogform.validate())
        axios
          .post(
            this.$store.state.backend_url +
              "api/document-templates/updateDocumentDetailtAttribute",
            this.StaffForm
          )
          .then((res) => {
            this.getList();
            this.StaffDialog = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: this.$t("create_update_operation"),
            });
          })
          .catch((err) => {
            console.log(err);
          });
    },
    editStaff(item) {
      this.dialogHeaderText = this.$t("edit staff");
      this.StaffForm = Object.assign({}, item);
      this.StaffDialog = true;
      this.editMode = true;
      if (this.$refs.staffDialogform)
        this.$refs.staffDialogform.resetValidation();
    },
    deleteStaff(item) {
      const index = this.items.indexOf(item);
      Swal.fire({
        title: this.$t("swal_title"),
        text: this.$t("swal_text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("swal_delete"),
      }).then((result) => {
        if (result.value) {
          axios
            .delete(
              this.$store.state.backend_url +
                "api/document-templates/destroyDetailAttribute/" +
                item.id
            )
            .then((res) => {
              this.getList(this.page, this.itemsPerPage);
              this.StaffDialog = false;
              Swal.fire("Deleted!", this.$t("swal_deleted"), "success");
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: this.$t("swal_error_title"),
                text: this.$t("swal_error_text"),
                //footer: "<a href>Why do I have this issue?</a>"
              });
              console.log(err);
            });
        }
      });
    },
    newAttribute(item) {
      this.StaffForm = {
        id: Date.now(),
        document_template_id: item.id,
        attribute_name_uz_latin: "",
        attribute_name_uz_cyril: "",
        attribute_name_ru: "",
        value_min_lenght: "",
        value_max_lenght: "",
        description: "",
        data_type_id: "",
      };
      this.StaffDialog = true;
      this.editMode = false;
      if (this.$refs.staffDialogform) this.$refs.staffDialogform.reset();
    },
    updatePage($event) {
      this.getList();
    },
    updatePerPage($event) {
      this.getList();
    },
    getList() {
      this.loading = true;
      axios
        .post(this.$store.state.backend_url + "api/document-templates", {
          pagination: this.dataTableOptions,
          search: this.filter.search || "",
          filter: this.filter,
        })
        .then((response) => {
          this.items = response.data.data;
          this.from = response.data.from;
          this.server_items_length = response.data.total;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getRef() {
      axios
        .post(this.$store.state.backend_url + "api/staffs/get-ref", {
          language: "uz_latin",
        })
        .then((response) => {
          console.log("staffs/get-ref", response.data);
          this.branches = response.data.branches;
          this.departments = response.data.departments;
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .post(this.$store.state.backend_url + "api/document-types", {
          language: "uz_latin",
        })
        .then((response) => {
          console.log("document-types", response.data);
          this.documentTypes = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    newItem() {
      this.dialogHeaderText = this.$t("document-templates.newDistrict");
      this.form = {
        id: Date.now(),
        department_id: "",
        document_type_id: "",
        signer_group_id: "",
        name_uz_latin: "",
        name_uz_cyril: "",
        name_ru: "",
        decription_uz_latin: "",
        decription_uz_cyril: "",
        decription_ru: "",
      };
      this.dialog = true;
      this.editMode = false;
      if (this.$refs.dialogForm) this.$refs.dialogForm.reset();
    },
    editItem(item) {
      this.dialogHeaderText = this.$t("document-templates.newDistrict");
      this.formIndex = this.items.indexOf(item);
      this.form = Object.assign({}, item);
      this.dialog = true;
      this.editMode = true;
      if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation();
    },
    save() {
      if (this.$refs.dialogForm.validate())
        axios
          .post(
            this.$store.state.backend_url + "api/document-templates/update",
            this.form
          )
          .then((res) => {
            this.getList();
            this.dialog = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: $t("create_update_operation"),
            });
          })
          .catch((err) => {
            console.log(err);
          });
    }, //document-types
    deleteItem(item) {
      const index = this.items.indexOf(item);
      Swal.fire({
        title: this.$t("swal_title"),
        text: this.$t("swal_text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("swal_delete"),
      }).then((result) => {
        if (result.value) {
          axios
            .delete(
              this.$store.state.backend_url +
                "api/document-templates/delete/" +
                item.id
            )
            .then((res) => {
              this.getList(this.page, this.itemsPerPage);
              this.dialog = false;
              Swal.fire("Deleted!", this.$t("swal_deleted"), "success");
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: this.$t("swal_error_title"),
                text: this.$t("swal_error_text"),
                //footer: "<a href>Why do I have this issue?</a>"
              });
              console.log(err);
            });
        }
      });
    },
  },
  mounted() {
    this.getRef();
    this.getList();
  },
};
</script>

<style>
.text-start {
  /* width: 50px; */
}
</style>
